var exports = {};

function getCurrentRequest(loaderContext) {
  if (loaderContext.currentRequest) {
    return loaderContext.currentRequest;
  }

  const request = loaderContext.loaders.slice(loaderContext.loaderIndex).map(obj => obj.request).concat([loaderContext.resource]);
  return request.join("!");
}

exports = getCurrentRequest;
export default exports;