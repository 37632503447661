var exports = {};

function getRemainingRequest(loaderContext) {
  if (loaderContext.remainingRequest) {
    return loaderContext.remainingRequest;
  }

  const request = loaderContext.loaders.slice(loaderContext.loaderIndex + 1).map(obj => obj.request).concat([loaderContext.resource]);
  return request.join("!");
}

exports = getRemainingRequest;
export default exports;