import _parseQuery from "./parseQuery";
var exports = {};
const parseQuery = _parseQuery;

function getOptions(loaderContext) {
  const query = loaderContext.query;

  if (typeof query === "string" && query !== "") {
    return parseQuery(loaderContext.query);
  }

  if (!query || typeof query !== "object") {
    // Not object-like queries are not supported.
    return null;
  }

  return query;
}

exports = getOptions;
export default exports;